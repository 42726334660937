/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { Layout, StaticSEO, StrapiSectionListContainer } from '@core';
import { ContactSection } from '@composite';

export default () => {
  const ref = React.useRef(null);

  return (
    <Layout>
      <StaticSEO pageId='data-privacy' />

      <StrapiSectionListContainer pageId='data-privacy' ref={ref} />

      <ContactSection id='landing.contact' triggerRef={() => ref} />
    </Layout>
  );
};
